import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://dfab7ea352c81802492d2c6affe2805c@o249348.ingest.us.sentry.io/4508592033890304",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});